const drawCurve = (
    ctx,
    width,
    height,
    zoomLevel,
    samplingFrequency,
    panOffset,
    dataPoints,
    showRPeaks,
    highlightedSegments,
    rPeakDataPoints
  ) => {
    // Effacer le canvas
    ctx.clearRect(0, 0, width, height);
  
    // Décalage horizontal pour éviter la superposition sur les labels Y
    const xOffset = 50;
  
    // Durée visible de la courbe (5 secondes par défaut, ajustée par le niveau de zoom)
    const visibleDuration = 5 / zoomLevel; // en secondes
  
    // Calculer le nombre de points visibles
    const totalPointsVisible = Math.floor(visibleDuration * samplingFrequency);
  
    // Échelle horizontale pour ajuster la largeur totale du graphique
    const xScale = (width - xOffset) / totalPointsVisible;
  
    // Limites pour l'axe Y (de -2 mV à 2 mV)
    const yMin = -2; // -2 mV
    const yMax = 2;  // 2 mV
  
    // Échelle verticale pour que l'axe Y aille de yMin à yMax
    const yScale = height / (yMax - yMin);
  
    // Positionner le 0V au milieu
    const yOffset = height * (yMax / (yMax - yMin));
  
    // Dessiner la courbe ECG
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 2;
    ctx.beginPath();
  
    // Calculer l'indice de départ et de fin en fonction du panOffset
    const startIndex = Math.max(0, Math.floor(panOffset));
    const endIndex = Math.min(dataPoints.length, startIndex + totalPointsVisible);
  
    dataPoints.slice(startIndex, endIndex).forEach((point, index) => {
      // Calculer la position x en tenant compte de l'offset du pan
      const x = xOffset + ((index + startIndex - panOffset) * xScale);
      const y = yOffset - (point * 1000 * yScale); // Conversion de V en mV et ajustement avec yOffset
  
      if (index === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    });
    ctx.stroke();
  
    // Dessiner les points R-Peaks si activé
    if (showRPeaks) {
      ctx.fillStyle = 'red';
      rPeakDataPoints.forEach((peak, index) => {
        const time = peak.time; // Temps en secondes
  
        // Ne dessiner que si le R-Peak est dans la fenêtre visible
        if (time < panOffset / samplingFrequency || time > (panOffset / samplingFrequency + visibleDuration)) return;
  
        // Convertir le temps en position x
        const adjustedTime = time - panOffset / samplingFrequency;
        const x = xOffset + adjustedTime * samplingFrequency * xScale;
  
        // Convertir le voltage en position y
        const y = yOffset - (peak.voltage * 1000 * yScale);
  
        // Log de débogage pour vérifier les coordonnées
        console.log(`R-Peak ${index}: time=${time}, voltage=${peak.voltage}, x=${x}, y=${y}`);
  
        // Dessiner le point
        ctx.beginPath();
        ctx.arc(x, y, 4, 0, 2 * Math.PI);
        ctx.fill();
      });
    }
  
    // Surligner les segments QRS, P, T si activés
    highlightedSegments.forEach((segment) => {
      const xStart = xOffset + ((segment.range[0] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
      const xEnd = xOffset + ((segment.range[1] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
      ctx.fillStyle = segment.backgroundColor;
      ctx.fillRect(xStart, 0, xEnd - xStart, height);
    });
  
    // Dessiner les labels de l'axe X (temps en secondes)
    ctx.fillStyle = 'black';
    ctx.font = '12px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
  
    for (let i = 0; i <= visibleDuration * 5; i++) { // 5 x 0.2s = 1s
      const timeLabel = (panOffset / samplingFrequency) + i * 0.2;
      const xLabel = xOffset + i * 0.2 * samplingFrequency * xScale;
  
      if (xLabel >= xOffset && xLabel <= width) {
        ctx.save();
        ctx.translate(xLabel, height - 20);
        ctx.rotate(-Math.PI / 2);
        ctx.fillText(`${timeLabel.toFixed(1)} s`, 0, 0);
        ctx.restore();
      }
    }
  };
  
  export default drawCurve;
  