import React from 'react';
import { Button } from '@mui/material';

const SaveButton = ({ rPeaks, onSave }) => {
  const handleSave = () => {
    // Vous pouvez ici effectuer une requête API pour sauvegarder la liste,
    // par exemple avec fetch ou axios, ou bien l'enregistrer dans localStorage.
    console.log("Sauvegarde des R-Peaks:", rPeaks);
    if (onSave) {
      onSave(rPeaks);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleSave}>
      Save
    </Button>
  );
};

export default SaveButton;
