import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Supposons qu'on ait une fonction d'i18n `t(key)`.
const t = (key) => {
  const dict = {
    'chart.title': 'R-R Intervals Chart',
    'chart.fc': 'FC',             // ou 'Fréquence cardiaque'
    'chart.rrInterval': 'RR interval', // ou 'Intervalle R-R'
  };
  return dict[key] || key;
};

const RRIntervalsChart = ({ rrIntervals }) => {
  const gridCanvasRef = useRef(null);
  const curveCanvasRef = useRef(null);

  // 'RR' = affichage des intervalles R-R, 'HR' = affichage de la FC
  const [displayMode, setDisplayMode] = useState('RR');

  // Basculer entre RR et HR quand l'utilisateur actionne le switch
  const handleSwitchChange = (event) => {
    setDisplayMode(event.target.checked ? 'HR' : 'RR');
  };

  // Calcul des temps cumulés (en secondes) pour l'axe des X
  const cumulativeTimes = rrIntervals.reduce((acc, interval) => {
    if (acc.length === 0) {
      acc.push(interval);
    } else {
      acc.push(acc[acc.length - 1] + interval);
    }
    return acc;
  }, []);
  const totalDuration = cumulativeTimes[cumulativeTimes.length - 1];

  /**
   * Dessin de la grille
   */
  useEffect(() => {
    if (!rrIntervals || rrIntervals.length === 0) return;

    const drawGrid = (ctx, width, height, mode) => {
      ctx.clearRect(0, 0, width, height);

      // Échelle X : en secondes
      const totalSeconds = Math.ceil(totalDuration);
      const xStep = width / totalSeconds;

      // Échelle Y
      let yMin, yMax, gridInterval;
      if (mode === 'RR') {
        // Intervalles R-R en ms
        yMin = 200;
        yMax = 1550;
        gridInterval = 100;
      } else {
        // FC en bpm : de 30 à max(250, freqMax)
        const minRR = Math.min(...rrIntervals); // en secondes
        const maxFreq = 60 / minRR; // 60 / intervalle minimal = FC max
        yMin = 30;
        yMax = Math.max(250, maxFreq);
        gridInterval = 10;
      }

      const yStep = height / (yMax - yMin);

      // Grille verticale (secondes)
      ctx.strokeStyle = 'rgba(255, 165, 0, 0.8)';
      ctx.lineWidth = 1;
      for (let i = 0; i <= totalSeconds; i++) {
        const x = i * xStep;
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();

        // Label en bas (secondes)
        ctx.font = '12px Arial';
        ctx.fillStyle = 'black';
        ctx.fillText(i.toString(), x - 5, height + 15);
      }

      // Grille horizontale (RR ou FC)
      for (let val = yMin; val <= yMax; val += gridInterval) {
        const y = height - (val - yMin) * yStep;
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();

        // Label à gauche (valeurs Y)
        ctx.fillText(val.toFixed(0), -30, y + 3);
      }
    };

    const handleResize = () => {
      if (gridCanvasRef.current) {
        const gridCanvas = gridCanvasRef.current;
        gridCanvas.width = gridCanvas.parentElement.clientWidth;
        gridCanvas.height = gridCanvas.parentElement.clientHeight;

        const gridCtx = gridCanvas.getContext('2d');
        // Décalage pour laisser de la place aux labels
        gridCtx.translate(40, 0);

        drawGrid(gridCtx, gridCanvas.width - 40, gridCanvas.height - 20, displayMode);

        // Réinitialisation de la transformation
        gridCtx.translate(-40, 0);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [rrIntervals, totalDuration, displayMode]);

  /**
   * Dessin de la courbe
   */
  useEffect(() => {
    if (!rrIntervals || rrIntervals.length === 0) return;

    const drawCurve = (ctx, width, height, dataPoints, mode) => {
      ctx.clearRect(0, 0, width, height);

      let yMin, yMax;
      if (mode === 'RR') {
        // Intervalles R-R en ms
        yMin = 200;
        yMax = 1550;
      } else {
        // FC en bpm
        const minRR = Math.min(...dataPoints);
        const maxFreq = 60 / minRR;
        yMin = 30;
        yMax = Math.max(250, maxFreq);
      }

      function drawCross(ctx, x, y, size) {
        ctx.beginPath();
        // Ligne verticale
        ctx.moveTo(x, y - size);
        ctx.lineTo(x, y + size);
        // Ligne horizontale
        ctx.moveTo(x - size, y);
        ctx.lineTo(x + size, y);
        ctx.stroke();
      }

      ctx.strokeStyle = 'blue';
      ctx.lineWidth = 1;
      ctx.beginPath();

      dataPoints.forEach((interval, index) => {
        // X proportionnel au temps cumulé
        const x = (cumulativeTimes[index] / totalDuration) * (width - 40);

        // Valeur Y selon le mode
        let value;
        if (mode === 'RR') {
          value = interval * 1000; // conversion s → ms
        } else {
          value = 60 / interval;   // FC = 60 / (RR en s)
        }

        // Normalisation sur l'axe Y
        const y = height - 20 - ((value - yMin) / (yMax - yMin)) * (height - 20);

        if (index === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });

      ctx.stroke();

      // Dessin des croix
      ctx.strokeStyle = 'blue';
      dataPoints.forEach((interval, index) => {
        const x = (cumulativeTimes[index] / totalDuration) * (width - 40);

        let value;
        if (mode === 'RR') {
          value = interval * 1000;
        } else {
          value = 60 / interval;
        }

        const y = height - 20 - ((value - yMin) / (yMax - yMin)) * (height - 20);
        drawCross(ctx, x, y, 3);
      });
    };

    const handleResize = () => {
      if (curveCanvasRef.current) {
        const curveCanvas = curveCanvasRef.current;
        const parentWidth = curveCanvas.parentElement.clientWidth;
        const parentHeight = curveCanvas.parentElement.clientHeight;

        if (parentWidth === 0 || parentHeight === 0) {
          console.error("Le parent du canvas n'a pas de dimensions. Vérifiez la mise en page.");
          return;
        }

        curveCanvas.width = parentWidth;
        curveCanvas.height = parentHeight;

        const curveCtx = curveCanvas.getContext('2d');
        // Décalage pour les labels
        curveCtx.translate(40, 0);

        drawCurve(curveCtx, curveCanvas.width, curveCanvas.height, rrIntervals, displayMode);

        // Réinitialisation de la transformation
        curveCtx.translate(-40, 0);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [rrIntervals, cumulativeTimes, totalDuration, displayMode]);

  return (
    <Accordion defaultExpanded={false} sx={{ backgroundColor: 'transparent' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{t('chart.title')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Centrage du switch et label dynamique */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={displayMode === 'HR'}
                onChange={handleSwitchChange}
                color="primary"
              />
            }
            // Label qui dépend du mode d'affichage
            label={displayMode === 'HR' ? t('chart.fc') : t('chart.rrInterval')}
          />
        </Box>

        <Box position="relative" width="100%" height="400px">
          {/* Canvas de la grille */}
          <canvas
            ref={gridCanvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              width: '100%',
              height: '100%',
            }}
          />
          {/* Canvas de la courbe */}
          <canvas
            ref={curveCanvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RRIntervalsChart;
