const LEFT_MARGIN = 50; // Marge identique à celle utilisée dans drawCurve

/**
 * Convertit un temps (en secondes) en coordonnée X (en pixels) sur le canvas.
 */
function convertTimeToX(time, canvasWidth, panOffset, samplingFrequency, zoomLevel) {
  const xOffset = LEFT_MARGIN;
  const visibleDuration = 5 / zoomLevel;
  const totalPointsVisible = visibleDuration * samplingFrequency;
  const xScale = (canvasWidth - xOffset) / totalPointsVisible;
  return xOffset + (time - panOffset / samplingFrequency) * samplingFrequency * xScale;
}

/**
 * Convertit une position X (en pixels) en temps (en secondes).
 */
function convertXToTime(mouseX, canvasWidth, panOffset, samplingFrequency, zoomLevel) {
  const xOffset = LEFT_MARGIN;
  const visibleDuration = 5 / zoomLevel;
  const totalPointsVisible = visibleDuration * samplingFrequency;
  const xScale = (canvasWidth - xOffset) / totalPointsVisible;
  return panOffset / samplingFrequency + (mouseX - xOffset) / (samplingFrequency * xScale);
}

/**
 * Convertit une position Y (en pixels) sur le canvas en tension (en Volts).
 */
function convertYToVoltage(mouseY, canvasHeight) {
  return (canvasHeight * 0.5 - mouseY) / (1000 * (canvasHeight / 4));
}

// RightClick.js
const handleRightClickOnCurve = (
  event,
  samplingFrequency,
  curveCanvasRef,
  rPeaks,
  panOffset,
  zoomLevel,
  setRPeaks,
  setDraggingRPeak,
  setSelectedRPeakIndex
) => {
  console.log('Clic droit détecté');
  event.preventDefault(); // Empêcher le menu contextuel natif

  const canvas = curveCanvasRef.current;
  if (!canvas) return;
  const rect = canvas.getBoundingClientRect();
  const mouseX = event.clientX - rect.left;
  const mouseY = event.clientY - rect.top;

  // Vérifier si le clic est proche d'un R-Peak existant
  let clickedRPeakIndex = -1;
  rPeaks.forEach((peak, index) => {
    const x = convertTimeToX(
      peak.time,
      canvas.width,
      panOffset,
      samplingFrequency,
      zoomLevel
    );
    const y = canvas.height * 0.5 - (peak.voltage * 1000 * (canvas.height / 4));

    if (Math.abs(mouseX - x) < 5 && Math.abs(mouseY - y) < 5) {
      clickedRPeakIndex = index;
      console.log('R-Peak cliqué :', peak.time, peak.voltage);
    }
  });

  // Création d'un menu contextuel personnalisé
  const contextMenu = document.createElement('div');
  contextMenu.style.position = 'fixed';
  contextMenu.style.top = `${event.clientY}px`;
  contextMenu.style.left = `${event.clientX}px`;
  contextMenu.style.backgroundColor = 'white';
  contextMenu.style.border = '1px solid #ccc';
  contextMenu.style.padding = '8px';
  contextMenu.style.zIndex = '9999';
  contextMenu.style.boxShadow = '0px 4px 8px rgba(0,0,0,0.1)';
  contextMenu.style.minWidth = '120px';
  contextMenu.style.cursor = 'pointer';

  if (clickedRPeakIndex !== -1) {
    // Suppression du R-Peak existant
    const deleteOption = document.createElement('div');
    deleteOption.innerText = 'Supprimer R-Peak';
    deleteOption.style.padding = '4px';
    deleteOption.onclick = (e) => {
      e.stopPropagation();
      // Réinitialiser l'état de drag pour éviter toute mise à jour résiduelle
      setDraggingRPeak(false);
      setSelectedRPeakIndex(null);
      
      setRPeaks((prev) => {
        const updatedPeaks = prev.filter((_, i) => i !== clickedRPeakIndex);
        console.log('R-Peak supprimé, nouvelle liste :', updatedPeaks);
        return updatedPeaks;
      });
      contextMenu.remove();
    };
    contextMenu.appendChild(deleteOption);
  } else {
    const addOption = document.createElement('div');
    addOption.innerText = 'Ajouter R-Peak';
    addOption.style.padding = '4px';
    addOption.onclick = () => {
      // Utiliser les fonctions de conversion existantes
      const time = convertXToTime(mouseX, canvas.width, panOffset, samplingFrequency, zoomLevel);
      const voltage = convertYToVoltage(mouseY, canvas.height);
      console.log('Nouveau R-Peak ajouté:', { time, voltage });
    
      setRPeaks((prev) => {
        const updatedPeaks = [...prev, { time, voltage }];
        console.log('Nouvelle liste de R-Peaks après ajout:', updatedPeaks);
        // Pour garantir une nouvelle référence, on retourne une copie triée
        return [...updatedPeaks].sort((a, b) => a.time - b.time);
      });
      
      contextMenu.remove();
    };
    contextMenu.appendChild(addOption);
  }

  document.body.appendChild(contextMenu);
  console.log('Menu contextuel ajouté au document', contextMenu);

  // Supprimer le menu contextuel si l'utilisateur clique ailleurs
  const removeContextMenu = (e) => {
    if (!contextMenu.contains(e.target)) {
      contextMenu.remove();
      document.removeEventListener('click', removeContextMenu);
    }
  };
  document.addEventListener('click', removeContextMenu);
};

export default handleRightClickOnCurve;

