import React from 'react';
import { Page, Text, View, StyleSheet, Document, Image } from '@react-pdf/renderer';
import WatchIconImage from '../../assets/images/watch.png';
import HeartIconImage from '../../assets/images/heart.png';
import StatusIconImage from '../../assets/images/status.png';


const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 12,
        backgroundColor: '#fff',
    },
    section: {
        marginBottom: 16,
    },
    header: {
        fontSize: 18,
        marginBottom: 16,
        fontWeight: 'bold',
        color: '#333',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    card: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 10,
        padding: 12,
        backgroundColor: '#fff',
        flex: 1,
        alignItems: 'center',
        marginHorizontal: 8,
    },
    icon: {
        width: 40,
        height: 40,
        marginBottom: 8,
    },
    boldText: {
        fontWeight: 'bold',
        marginBottom: 4,
        color: '#555',
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        marginVertical: 8,
    },
    detailRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 6,
    },
    reference: {
        fontSize: 10,
        color: 'gray',
        textAlign: 'right',
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#007FFF',
        marginBottom: 8,
    },
    container: {
        backgroundColor: '#fff',
        padding: 12,
        borderRadius: 10,
    },
});

const PDFECGDetails = ({ ecg, patient }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* ECG Details Header */}
            <View style={styles.section}>
                <Text style={styles.header}>Informations de l'ECG</Text>
            </View>

            {/* Device, Heart Rate, and Status */}
            <View style={styles.row}>
                <View style={styles.card}>
                    <Image style={styles.icon} src={WatchIconImage} />
                    <Text style={styles.boldText}>Appareil:</Text>
                    <Text>{ecg.device || '-'}</Text>
                </View>
                <View style={styles.card}>
                    <Image style={styles.icon} src={HeartIconImage} />
                    <Text style={styles.boldText}>Fréquence cardiaque:</Text>
                    <Text>{ecg.heartRate ? `${Math.round(ecg.heartRate)} bpm` : '-'}</Text>
                </View>
                <View style={styles.card}>
                    <Image style={styles.icon} src={StatusIconImage} />
                    <Text style={styles.boldText}>Statut:</Text>
                    <Text>{ecg.ECGStatus || '-'}</Text>
                </View>
            </View>

            {/* Basic Information */}
            <View style={styles.section}>
                <View style={styles.container}>
                    <Text style={styles.title}>Informations standards</Text>
                    <View style={styles.divider} />
                    <View>
                        <View style={styles.detailRow}>
                            <Text>QT:</Text>
                            <Text>{ecg.QT ? `${Math.round(ecg.QT * 1000)} ms` : '-'}</Text>
                            <Text style={styles.reference}>{'< 420 ms'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>QTcB:</Text>
                            <Text>{ecg.QTcB ? `${Math.round(ecg.QTcB * 1000)} ms` : '-'}</Text>
                            <Text style={styles.reference}>{patient.gender === 'female' ? '< 460 ms' : '< 440 ms'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>QTcF:</Text>
                            <Text>{ecg.QTcF ? `${Math.round(ecg.QTcF * 1000)} ms` : '-'}</Text>
                            <Text style={styles.reference}>{patient.gender === 'female' ? '< 460 ms' : '< 440 ms'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>Intervalle PR:</Text>
                            <Text>{ecg.PRInterval ? `${Math.round(ecg.PRInterval)} ms` : '-'}</Text>
                            <Text style={styles.reference}>120 - 200 ms</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>Durée du complexe QRS:</Text>
                            <Text>{ecg.QRSDuration ? `${Math.round(ecg.QRSDuration)} ms` : '-'}</Text>
                            <Text style={styles.reference}>{'< 120 ms'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>Intervalle RR:</Text>
                            <Text>{ecg.RRInterval ? `${Math.round(ecg.RRInterval)} ms` : '-'}</Text>
                            <Text style={styles.reference}>Variable</Text>
                        </View>
                    </View>
                </View>
            </View>

            {/* Additional Information */}
            <View style={styles.section}>
                <View style={styles.container}>
                    <Text style={styles.title}>Informations complémentaires</Text>
                    <View style={styles.divider} />
                    <View>
                        <View style={styles.detailRow}>
                            <Text>Écart-type des variations RR:</Text>
                            <Text>{ecg.SDNN ? `${Math.round(ecg.SDNN)} ms` : '-'}</Text>
                            <Text style={styles.reference}>{'< 30 ms'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>CVRR:</Text>
                            <Text>{ecg.CVRR ? `${Math.round(ecg.CVRR * 100)} %` : '-'}</Text>
                            <Text style={styles.reference}>{'< 20 %'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>PNN50:</Text>
                            <Text>{ecg.PNN50 ? `${Math.round(ecg.PNN50)}` : '-'}</Text>
                            <Text style={styles.reference}>{'< 5 %'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>Indice de fibrillation auriculaire:</Text>
                            <Text>{ecg.FAIndex ? `${Math.round(ecg.FAIndex * 100) / 100}` : '-'}</Text>
                            <Text style={styles.reference}>{'< 1'}</Text>
                        </View>
                        <View style={styles.detailRow}>
                            <Text>Probabilité de fibrillation atriale:</Text>
                            <Text>{ecg.FAProbability ? `${Math.round(ecg.FAProbability)} %` : '-'}</Text>
                            <Text style={styles.reference}>{'< 30%'}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default PDFECGDetails;
