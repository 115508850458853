import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VoltageMeasurementsGraph from './VoltageMeasurementGraph/VoltageMeasurementsGraph';
import SaveButton from './VoltageMeasurementGraph/SaveButton';
import { updateECGDetails } from '../api/ECG';

const ECGDisplay = ({ ecg = {}, patient = {} }) => {
    const gridCanvasRef = useRef([]);
    const curveCanvasRef = useRef([]);
    const [interactiveMode, setInteractiveMode] = useState(false);
    const [rPeaks, setRPeaks] = useState(ecg.rPeaks || []);

    // Conserver la version "originale" des R-Peaks dès le montage
    const [originalRPeaks, setOriginalRPeaks] = useState(rPeaks);

    // On considère qu'il y a des modifications si le nombre de R-Peaks change
    const hasChanges = rPeaks.length !== originalRPeaks.length;

    useEffect(() => {
        // Si ecg.rPeaks change (par ex. au chargement initial), on met à jour le state
        setRPeaks(ecg.rPeaks || []);
        setOriginalRPeaks(ecg.rPeaks || []);
    }, [ecg.rPeaks]);

    useEffect(() => {
        if (!ecg || !ecg.voltageMeasurements || interactiveMode) return;

        const drawGrid = (ctx, width, height, index) => {
            const mmPerSecond = 25;
            const mmPerMV = 10;
            const totalSecondsPerLine = 10;
            const totalMillimetersX = totalSecondsPerLine * mmPerSecond;
            const pixelsPerMillimeterX = width / totalMillimetersX;
            const majorGridSizeX = 5 * pixelsPerMillimeterX;
            const minorGridSizeX = pixelsPerMillimeterX;
            const yMin = -2;
            const yMax = 2;
            const totalMillimetersY = (yMax - yMin) * mmPerMV;
            const pixelsPerMillimeterY = height / totalMillimetersY;
            const majorGridSizeY = 10 * pixelsPerMillimeterY;
            const minorGridSizeY = pixelsPerMillimeterY;
            const xLabelOffset = 50;

            ctx.clearRect(0, 0, width, height);

            ctx.strokeStyle = 'rgba(255, 165, 0, 0.8)';
            ctx.lineWidth = 1;
            for (let x = 0; x <= width; x += majorGridSizeX) {
                ctx.beginPath();
                ctx.moveTo(x + xLabelOffset, 0);
                ctx.lineTo(x + xLabelOffset, height);
                ctx.stroke();
            }
            for (let y = 0; y <= height; y += majorGridSizeY) {
                ctx.beginPath();
                ctx.moveTo(xLabelOffset, y);
                ctx.lineTo(width + xLabelOffset, y);
                ctx.stroke();
            }

            ctx.strokeStyle = 'rgba(255, 182, 107, 0.5)';
            ctx.lineWidth = 0.5;
            for (let x = 0, countX = 0; x <= width; x += minorGridSizeX, countX++) {
                ctx.strokeStyle = (countX % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
                ctx.lineWidth = (countX % 5 === 0) ? 1 : 0.5;
                ctx.beginPath();
                ctx.moveTo(x + xLabelOffset, 0);
                ctx.lineTo(x + xLabelOffset, height);
                ctx.stroke();
            }
            for (let y = 0, countY = 0; y <= height; y += minorGridSizeY, countY++) {
                ctx.strokeStyle = (countY % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
                ctx.lineWidth = (countY % 5 === 0) ? 1 : 0.5;
                ctx.beginPath();
                ctx.moveTo(xLabelOffset, y);
                ctx.lineTo(width + xLabelOffset, y);
                ctx.stroke();
            }

            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            const timeOffset = index * 10;
            for (let x = 0; x <= width; x += majorGridSizeX * 5) {
                const timeLabel = Math.round((x / (majorGridSizeX * 5)) + timeOffset);
                ctx.fillText(`${timeLabel} s`, x + xLabelOffset, height - 5);
            }
            for (let y = 0; y <= height; y += majorGridSizeY) {
                const voltageLabel = (yMax - (y / height) * (yMax - yMin)).toFixed(1);
                ctx.fillText(`${voltageLabel} mV`, 2, y - 2);
            }
        };

        const handleResize = () => {
            gridCanvasRef.current.forEach((canvas, index) => {
                if (canvas) {
                    canvas.width = canvas.parentElement.clientWidth;
                    canvas.height = canvas.parentElement.clientHeight;
                    const gridCtx = canvas.getContext('2d');
                    drawGrid(gridCtx, canvas.width, canvas.height, index);
                }
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ecg, interactiveMode]);

    useEffect(() => {
        if (!ecg.voltageMeasurements || interactiveMode) return;

        const drawCurve = (ctx, width, height, dataPoints) => {
            ctx.clearRect(0, 0, width, height);
            const xOffset = 50;
            const visibleDuration = 10;
            const totalPointsVisible = Math.floor(visibleDuration * ecg.samplingFrequency);
            const xScale = (width - xOffset) / totalPointsVisible;
            const yMin = -2;
            const yMax = 2;
            const yScale = height / (yMax - yMin);
            const yOffset = height * (yMax / (yMax - yMin));

            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.beginPath();

            const startIndex = 0;
            const endIndex = Math.min(dataPoints.length, startIndex + totalPointsVisible);

            dataPoints.slice(startIndex, endIndex).forEach((point, index) => {
                const x = xOffset + (index * xScale);
                const y = yOffset - (point.voltage * 1000 * yScale);

                if (index === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }
            });
            ctx.stroke();
        };

        const handleResize = () => {
            curveCanvasRef.current.forEach((canvas, index) => {
                if (canvas) {
                    canvas.width = canvas.parentElement.clientWidth;
                    canvas.height = canvas.parentElement.clientHeight;
                    const curveCtx = canvas.getContext('2d');

                    const startIndex = index * ecg.samplingFrequency * 10;
                    const endIndex = startIndex + ecg.samplingFrequency * 10;

                    const dataPoints = ecg.voltageMeasurements?.slice(startIndex, endIndex).map((item) => item);
                    drawCurve(curveCtx, canvas.width, canvas.height, dataPoints);
                }
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ecg, interactiveMode]);

    return (
        <Accordion defaultExpanded={false} sx={{ backgroundColor: 'transparent' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Affichage de l'ECG</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                    <FormControlLabel
                        control={<Switch checked={interactiveMode} onChange={(e) => setInteractiveMode(e.target.checked)} />}
                        label="Mode interactif"
                    />
                    {interactiveMode ? (
                        <>
                            {/* Box contenant les canvases interactifs */}
                            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                                <VoltageMeasurementsGraph
                                    voltageMeasurements={ecg.voltageMeasurements}
                                    filteredVoltageMeasurements={ecg.filteredVoltageMeasurements}
                                    rPeaks={rPeaks}
                                    setRPeaks={setRPeaks}
                                    filteredRPeaks={ecg.filteredRPeaks}
                                    qrsComplexes={ecg.qrsComplexes}
                                    pPeaks={ecg.pPeaks}
                                    tPeaks={ecg.tPeaks}
                                    samplingFrequency={ecg.samplingFrequency}
                                />
                            </Box>
                            {/* Box en dessous pour le bouton Save */}
                            {hasChanges && (
                                <Box mt={2} width="100%" display="flex" justifyContent="center">
                                    <SaveButton
                                        rPeaks={rPeaks}
                                        onSave={(peaks) => {
                                            console.log("R-Peaks sauvegardés:", peaks);
                                            updateECGDetails(patient, ecg.id, { rPeaks: peaks })
                                                .then(response => {
                                                    console.log("Mise à jour réussie :", response.data);
                                                    // Mettre à jour la version originale pour masquer le bouton Save
                                                    setOriginalRPeaks(peaks);
                                                })
                                                .catch(error => {
                                                    console.error("Erreur lors de la mise à jour :", error);
                                                });
                                        }}
                                    />
                                </Box>
                            )}
                        </>
                    ) : (
                        // Mode non interactif (vos canvases en mode affichage)
                        [0, 1, 2].map((rowIndex) => (
                            <Box key={rowIndex} position="relative" width="100%" height="150px" marginBottom="20px">
                                <canvas
                                    ref={(el) => (gridCanvasRef.current[rowIndex] = el)}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                                <canvas
                                    ref={(el) => (curveCanvasRef.current[rowIndex] = el)}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </Box>
                        ))
                    )}

                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ECGDisplay;
