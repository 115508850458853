import React from 'react';
import {
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import { useTranslation } from 'react-i18next';


const RPeaks = ({ showRPeaks, setShowRPeaks }) => {
    const { t } = useTranslation();

    return(
        <FormControlLabel
        control={
            <Checkbox
                checked={showRPeaks}
                onChange={(e) => setShowRPeaks(e.target.checked)}
                color="primary"
            />
        }
        label={t('ecg.display_r_peaks')}
    />
    );

};

export default RPeaks;
 